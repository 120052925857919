<template>
<div>
    <div class="basics-info-title">
        <span class="text">{{title}}</span>
        <el-button type="text" :class="{'isDown': flag}" icon="iconfont iconright" class="btn"
        @click="flag = !flag">{{flag ? '收起':'展开'}}</el-button>
    </div>
    <el-collapse-transition>
        <div v-show="flag">
            <slot></slot>
        </div>
    </el-collapse-transition>
</div>
</template>
<script>
export default {
    props: {
        title: {
            default: '',
            type: String
        }
    },
    data() {
        return {
            flag: true
        };
    }
};
</script>
<style lang="less">
.basics-info-title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .text{
        font-size: 1.17em;
        font-weight: bold;
        line-height: 3.17em;
    }
    .btn{
        span{
            float: left;
            color: #c1c1c1;
            margin-right: 5px;
        }
        i{
            transition: transform .3s;
            display: inline-block;
        }
    }
    .isDown{
        i{
            transform: rotate(90deg);
        }
    }
}
</style>
