<template>
    <div class="tianfu-iflow-table">
        <el-table
            height="325"
            v-loading="iflowTalbeList.tableLoading"
            :data="iflowTalbeList.totalData"
            border>
            <el-table-column
                prop="mipType"
                label="流程类型"
                width="80">
                <template slot-scope='scope'>
                    {{ dictData.MIP_TYPE && dictData.MIP_TYPE[scope.row.mipType] || '--'}}
                </template>
            </el-table-column>
            <el-table-column
                prop="mipStatus"
                label="审批状态"
                width="80">
                <template slot-scope='scope'>
                     {{ dictData.SYS_SU_MIP_AUDIT_STATUS && dictData.SYS_SU_MIP_AUDIT_STATUS[scope.row.mipStatus] || '--'}}
                </template>
            </el-table-column>
            <el-table-column
                prop="mipId"
                label="mip审批流编码"
                width="160">
            </el-table-column>
            <el-table-column
                prop="createUserCode"
                label="创建人"
                width="100">
            </el-table-column>
            <el-table-column
                prop="createTime"
                label="创建时间"
                width="160">
            </el-table-column>
            <el-table-column
                prop="mipUrl"
                label="审批流">
                <template slot-scope="scope">
                    <el-button
                    size="mini"
                    type="text"
                    @click="handleLink('mipUrl', scope.row)">{{ scope.row.mipUrl || '' }}</el-button>
                </template>
            </el-table-column>
            <el-table-column
                prop="legalMipUrl"
                label="法务审批流">
                <template slot-scope="scope">
                    <el-button
                    size="mini"
                    type="text"
                    @click="handleLink('legalMipUrl', scope.row)">{{ scope.row.legalMipUrl || '' }}</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="footer">
            <lots-pagination
                @size-change='iflowTalbeList.sizeChange'
                :current-page.sync='iflowTalbeList.pageNo'
                @current-change='iflowTalbeList.pageChange'
                size="small" layout='total, sizes, prev, pager, next, jumper'
                :total='iflowTalbeList.pageTotal'/>
        </div>
    </div>
</template>

<script>
import lotsPagination from '@/components/lots/lotsPagination/Index';
import { dictWithUrl } from '@/components/lots/api';
import { AdvanceListClass } from '@/modules/mdm/composition/dc/common/list/AdvanceListClass';
import { contractWorkflowList } from '@mdm/api/tianfuBankContract.js';
import configurl from '@/config/user.env';
import { onMounted, getCurrentInstance, ref } from '@vue/composition-api';
export default {
    components: {
        lotsPagination
    },
    props: {
        contractCode: {
            type: String
        },
        currentContract: {
            type: Object,
            return: () => {}
        }

    },
    /* eslint-disable max-lines-per-function */
    setup(props, setupContext) {
        const { user } = setupContext.root.$store.getters;
        class IflowTalbeClass extends AdvanceListClass {
            beforeGetList(condition) {
                condition.contractCode = props.contractCode;
                return condition;
            }
            afterGetList(res) {
                if (+res.code === 0) {
                    res.data.list.forEach(row => {
                        row.enableFlagCN = row.enableFlag === 1 ? '启用' : '停用';
                    });
                }
                super.afterGetList(res);
            }
        };
        const iflowTalbeList = new IflowTalbeClass({
            Api: {
                listApi: contractWorkflowList
            }
        });
        const ctx = getCurrentInstance(); // 通过该方法访问当前组件的实例对象,相当于this
        const handleLink = (type, row) => {
            if (type === 'legalMipUrl') {
                window.open(row.legalMipUrl, '_blank');
            } else {
                SwitchHttpsProtocol();
                const { contractCode, contractName, contractCharacter, mipContractCode } = props.currentContract;
                const mipUrl = `${configurl.iflowUrl}?fdrouter=/processForm/view/${row.mipId}/`;
                ctx.$router.push({ path: '/mipIframe', query: { mipUrl, contractCode, contractName, mipContractCode, contractCharacter, userCode: user.userCode } });
            }
        };
        const SwitchHttpsProtocol = () => { // 1 判断进入页面的协议是否是http,如果是http将协议转换成https
            if (location.protocol === 'http:') {
                setTimeout(() => {
                    window.location.replace(
                        location.href.replace('http', 'https')
                    );
                }, 0);
            }
        };
        const dictData = ref({});
        onMounted(() => {
            dictWithUrl('MIP_TYPE,SYS_SU_MIP_AUDIT_STATUS').then(res => {
                dictData.value = res.data;
            }).finally(() => {
                iflowTalbeList.getList();
            });
        });
        return {
            dictData,
            iflowTalbeList,
            handleLink
        };
    }
};
</script>
<style lang='less'>
.tianfu-iflow-table {
    .footer {
        padding: 10px 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
}
</style>
