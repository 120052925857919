<template>
    <div>
        <el-dialog
            :title="title"
            class="orderImport"
            :visible.sync="orderImport"
            @close="cancelImport"
        >
            <el-row :gutter="5">
                <el-col :span="24">
                    <el-upload
                        class="upload-demo"
                        ref="upload"
                        :headers="reqHeaders"
                        :on-error="handleError"
                        :before-upload="beforeUpload"
                        :action="ImportConfig.action"
                        :data="upLoadData"
                        :on-preview="handlePreview"
                        :on-remove="handleRemove"
                        :auto-upload="!ImportConfig.manualUpload"
                        :multiple="ImportConfig.multiple"
                        :show-file-list="ImportConfig.showFileList"
                        :accept="ImportConfig.accept"
                        :on-exceed="handleExceed"
                        :limit="1"
                        :with-credentials="true"
                        :on-success="onSuccess"
                        :file-list="fileList"
                        :name="ImportConfig.name"
                    >
                        <el-button v-loading="showLoading" size="small" type="primary">{{$translate('component.E202007310005',{defaultText:'上传'})}}</el-button>
                        <span slot="tip" class="el-upload__tip">{{$translate('component.E202007310006',{defaultText:'上传要求'})}}：{{ImportConfig.fileType}}</span>
                    </el-upload>
                </el-col>
            </el-row>
            <template v-if="errorMsgList.length > 0">
                <ul class="error-text">
                    <li v-for="(msg, index) in errorMsgList" :key="index">{{msg}}</li>
                </ul>
            </template>
            <div class="error-text" v-if="errorMsgText">{{errorMsgText}}</div>
            <el-collapse v-if="showDownLoad" @change="handleChange">
                <el-collapse-item :title="$translate('component.E202007310008',{defaultText:'模板'})" name="1">
                    <div class="template_flex" v-for="(item,index) in ImportContent" :key="index">
                        <div class="template_left">
                            <div class="template">
                                <div>{{item.fileName}}</div>
                                <div>{{item.fileSize}}</div>
                            </div>
                        </div>
                        <div class="template_float">
                            <em
                                v-if="item.upLoadFlag"
                                class="el-icon-upload2 modelBtn"
                                @click="handleUpload(item)"
                            ></em>
                            <em class="el-icon-download modelBtn" @click="handleDownload(item)"></em>
                        </div>
                    </div>
                </el-collapse-item>
            </el-collapse>

            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="cancelImport">{{$translate('component.E202006180020',{defaultText:'取 消'})}}</el-button>
                <el-button size="small" type="primary" @click="savaData" :loading="showLoading">{{$translate('component.E202006152363',{defaultText:'确定'})}}</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import configurl from '@/config/user.env';
import { I18nMixins, $translate } from '@/components/mixins/i18n';
export default {
    mixins: [I18nMixins],
    data() {
        return {
            fileList: [],
            fileList2: [],
            errorMsgList: [],
            errorMsgText: '',
            orderImport: false,
            upLoadInfo: {}, // 上传信息
            uploadInput: {},
            flag: false, // 一个上不了页面的input标签
            showLoading: false
        };
    },
    props: {
        title: {
            type: String,
            default: () => {
                return $translate('component.E202007310007', { defaultText: '模板导入' });
            }
        },
        showDownLoad: {
            default: false
        },
        upLoadData: {
            type: Object,
            default: () => {
                return {};
            }
        },
        ImportConfig: {
            type: Object,
            default: () => {
                return {
                    action: configurl.apiUrl + '/api-lcp-order/aging/config/upload',
                    multiple: false,
                    accept: 'xlsx/text',
                    showFileList: true,
                    fileType: $translate('component.E202007310009', { defaultText: '只能上传Excel文件' }),
                    manualUpload: false,
                    name: 'file'
                };
            }
        },
        ImportContent: {
            type: Array,
            default: function (e) {
                return [
                    {
                        fileName: $translate('component.E202007310010', { defaultText: '导入模板' }) + '.xlsx',
                        fileSize: '28KB',
                        downLoadInfo: {
                            url: configurl.apiUrl + '/api-lcp-order/customer/aging/config/template/download'
                        }
                    }
                ];
            }
        }
    },
    computed: {
        ...mapState({
            reqHeaders(state) {
                const tenantCode = _.get(state, 'user.currentTenant.tenantCode', null);
                return { tenantCode };
            }
        })
    },
    created() {
    // 创建导入功能使用的Input标签并添加监听
        this.createInput();
    },
    methods: {
    // 创建导入功能使用的Input标签并添加监听
        createInput() {
            if (!this.flag) {
                return;
            }
            // 创建input标签并添加监听
            this.uploadInput = document.createElement('input');
            this.uploadInput.type = 'file';
        },
        // 上传模板
        handleUpload(item) {
            if (!this.flag) {
                return;
            }
            this.upLoadInfo = item.upLoadInfo;
            this.uploadInput.click();
        },
        beforeUpload(file) {
            this.showLoading = true;
            this.errorMsgList = [];
            this.errorMsgText = '';

            const isText = file.type === 'application/vnd.ms-excel';
            const isTextComputer
        = file.type
        === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            return isText | isTextComputer;
        },
        // 下载模板
        handleDownload(item) {
            if (item.downLoadInfo.url) {
                window.location = item.downLoadInfo.url;
            } else {
                this.$emit('downLoadTemplate', item);
            }
        },
        // 上传失败
        handleError() {
            this.showLoading = false;
            this.$lcpMessage.error('上传失败');
        },
        orderImportC() {
            //  触发弹窗
            this.orderImport = true;
            this.fileList = [];
            this.fileList2 = [];
            this.errorMsgList = [];
            this.errorMsgText = '';
            this.showLoading = false;
        },
        onSuccess(res) {
            this.showLoading = false;
            if (res.code === '0') {
                if (res.msg === 'Success') {
                    this.$lcpMessage.success('导入成功');
                    this.orderImport = false;
                    this.$emit('uploadClose');
                } else {
                    this.$emit('getUploadResult', res);
                }
            } else {
                this.$lcpMessage.error(res.msg);
                try {
                    this.errorMsgList = JSON.parse(res.msg);
                } catch (error) {
                    this.errorMsgText = res.msg;
                }
            }
        },
        handleChange(val) {
            // 导入下拉
        },
        handleRemove(file, fileList) {
            // 行内删除
            this.showLoading = false;
        },
        handlePreview(file) {},
        handleExceed(files, fileList) {
            this.$lcpMessage.warning('版本暂时不支持同时上传多个文件');
        },
        savaData() {
            // 保存
            this.$refs.upload.submit();
            if (!this.ImportConfig.manualUpload) {
                this.orderImport = false;
                this.$emit('uploadClose');
            }
        },
        cancelImport() {
            this.orderImport = false;
            this.errorMsgText = '';
        }
    }
};
</script>

<style lang="less">
.orderImport {
  .upload-demo {
    margin-top: 20px;
  }
  .error-text {
    color: #fff;
    background: #f56c6c;
    line-height: 2;
    padding: 5px 15px;
    border-radius: 5px;
    margin-top: 10px;
  }
  .el-upload__tip {
    margin-left: 20px;
    color: #999999;
  }

  .el-upload-list {
    padding: 0 10px;
    max-height: 60px;
    overflow-y: auto;
    margin-top: 15px;
  }

  .el-collapse-item__header {
    font-size: 14px;
    background-color: #f2f2f2;
    padding-left: 10px;
    color: #a3a3a3;
    margin-top: 10px;
    height: 40px;
    line-height: 40px;

    .el-icon-document:before {
      content: '\E614';
    }
  }

  .el-collapse {
    border: none;
  }

  .el-collapse-item__content {
    padding-bottom: 10px;
    max-height: 140px;
    overflow-y: auto;
  }

  .el-dialog__body {
    padding: 10px 10px;
  }

  .template_flex {
    display: flex;
    position: relative;
    margin-top: 10px;
    padding: 10px;
    border-bottom: 1px solid #f2f2f2;
  }

  .template_left {
    display: flex;
  }

  .template_left img {
    width: 30px;
    height: 40px;
  }

  .template {
    margin-left: 15px;
    font-size: 12px;
  }

  .template_float {
    font-size: 14px;
    letter-spacing: 10px;
    color: #00bcbf;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);

    .modelBtn {
      cursor: pointer;
    }
  }

  .el-collapse-item__wrap {
    border-bottom: none;
  }
}
</style>
