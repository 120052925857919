
import request from '@/utils/http';

// 处理接口文件流下载
const downloadStream = function (res, fileName = '下载文件.zip') {
    const content = res;
    const blob = new Blob([content]);
    if ('download' in document.createElement('a')) { // 非IE下载
        const elink = document.createElement('a');
        elink.download = fileName;
        elink.style.display = 'none';
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href);
        document.body.removeChild(elink);
    } else { // IE10+下载
        navigator.msSaveBlob(blob, fileName);
    }
};

// 天府银行合同-列表
export const contractListGet = (params) => {
    return request({
        url: 'api-dc/contractInfo',
        method: 'get',
        params
    });
};

// 天府银行合同-修改
export const contractUpdateByCode = (data, contractCode) => {
    return request({
        url: `api-dc/contractInfo/${contractCode}`,
        method: 'post',
        data
    });
};

// 天府银行合同-修改
export const contractDetailGet = (params, id) => {
    return request({
        url: `api-dc/contractInfo/${id}`,
        method: 'get',
        params
    });
};

// 天府银行合同-提交审批流程
export const applyIflowApi = (data) => {
    return request({
        url: 'api-dc/contractInfo/approveProcess',
        method: 'post',
        data
    });
};

// 天府银行合同-详情页-上传智慧签验签
export const pushFileToZHQ = (data) => {
    return request({
        url: 'api-dc/contractEnclosureInfo/pushFileToZhq',
        method: 'post',
        data
    });
};

// 天府银行合同-详情页-上传天府银行附件
export const pushFileToTF = (data) => {
    return request({
        url: 'api-dc/contractEnclosureInfo/pushFileToTf',
        method: 'post',
        data
    });
};

// 天府银行合同-详情页-附件信息-分页
export const contractFilesList = (params) => {
    return request({
        url: 'api-dc/contractEnclosureInfo',
        method: 'get',
        params
    });
};

// 天府银行合同-详情页-附件信息-生成合同
export const contractFilesAdd = (data) => {
    return request({
        url: 'api-dc/contractEnclosureInfo',
        method: 'post',
        data
    });
};

// 天府银行合同-详情页-附件信息-下载附件
export const contractFilesDownload = (params) => {
    return request({
        url: 'api-dc/contractEnclosureInfo/download',
        method: 'get',
        responseType: 'blob',
        params
    }).then(res => {
        downloadStream(res, params.contractName);
    });
};

// 天府银行合同-详情页-附件信息-上传附件
export const contractFilesUpload = (data) => {
    return request({
        url: 'api-dc/contractEnclosureInfo/upload',
        method: 'post',
        data
    });
};

// 天府银行合同-详情页-附件信息-预览附件
export const contractFilesView = (data) => {
    return request({
        url: 'api-dc/contractEnclosureInfo/view',
        method: 'post',
        data
    });
};

// 天府银行合同-详情页-附件信息-删除附件
export const contractFilesDelete = (data) => {
    return request({
        url: 'api-dc/contractEnclosureInfo',
        method: 'DELETE',
        data
    });
};

// 天府银行合同-详情页-合同抵押物-分页
export const contractCollateralList = (params) => {
    return request({
        url: 'api-dc/contractCollateralInfo',
        method: 'get',
        params
    });
};

// 天府银行合同-详情页-合同客户明细-分页
export const contractCustomerList = (params) => {
    return request({
        url: 'api-dc/contractCustomerInfo',
        method: 'get',
        params
    });
};

// 天府银行合同-详情页-流程-分页
export const contractWorkflowList = (params) => {
    return request({
        url: 'api-dc/contractWorkflowInfo',
        method: 'get',
        params
    });
};

// 提交mip审核
export const createProcess = params => {
    return request({
        // url: `/api-iflow/approveProcess?tenantCode=${params.tenantCode}&appCode=${params.currentApplicationCode}`,
        url: `api-dc/contractInfo/approve`,
        method: 'post',
        data: params
    }).then(res => res);
};
