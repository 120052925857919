/* eslint-disable */
/**
 * @description: 动态设置表格基础配置
 * @returns {object} 返回生成的配置对象
 */
const setTableConfig = (activeName) => {
    return tableConfigStrategies()[activeName]();
};

/**
 * @description: 策略模式分发
 * @return {object} 返回策略对象
 */

const tableConfigStrategies = () => {
    return {
        'filesDetail': filesDetailConfigStrategy,
        'customerDetail': customerDetailConfigStrategy,
        'pawnDetail': pawnDetailConfigStrategy,
        'iflowDetail': iflowDetailConfigStrategy
    };
};

/**
 * @description: 生成附件信息表格配置
 * @return {object} 返回策略配置对象
 */
const filesDetailConfigStrategy = () => {
    return {
        selection: false,
        height: 325,
        columns: [
            {
                label: '附件名称',
                prop: 'accessoryName'
            },
            {
                label: '合同名称',
                prop: 'contractName'
            },
            {
                label: '文件类型',
                prop: 'fileType',
                type: 'select',
                optionsKey: 'SYS_CR_CONTRACT_FILE_TYPE'
            },
            {
                label: '上传人',
                prop: 'createUserCode'
            },
            {
                label: '上传时间',
                prop: 'createTime'
            },
            {
                label: '模板',
                prop: 'contractName'
            }
        ],
        actions: {
            fixedWidth: 160,
            list: [
                {
                    label: '下载',
                    event: 'download'
                },
                {
                    label: '预览',
                    event: 'viewFile'
                },
                {
                    label: '删除',
                    event: 'del',
                    displayRule: () => {
                        return false;
                    }
                }
            ]
        },
        rules: {
            status: [{ required: true, message: '请选择', trigger: 'change' }],
            groupNameCn: [{ required: true, message: '请输入中文名称', trigger: 'blur' }]
        }
    };
};

/**
 * @description: 生成客户信息表格配置
 * @return {object} 返回策略配置对象
 */
const customerDetailConfigStrategy = () => {
    return {
        selection: false,
        height: 325,
        columns: [
            {
                label: '客户编码',
                prop: 'customerCode'
            },
            {
                label: '客户名称',
                prop: 'customerName'
            },
            {
                label: '对账方',
                prop: 'contrastAccount',
                type: 'select',
                optionsKey: 'ACCOUNTER_TYPE'
            },
            {
                label: '开票方',
                prop: 'openInvoice',
                type: 'select',
                optionsKey: 'ACCOUNTER_TYPE'
            },
            {
                label: '记账方',
                prop: 'recordAccount',
                type: 'select',
                optionsKey: 'ACCOUNTER_TYPE'
            },
            {
                label: '签约主体',
                prop: 'stampSubject',
                type: 'select',
                optionsKey: 'SYS_YES_NO'
            },
            {
                label: '运作比例',
                prop: 'proportion'
            },
            {
                label: '社会信用代码',
                prop: 'socialCreditCode'
            },
            {
                label: '住所',
                prop: 'residence'
            },
            {
                label: '法定代表人',
                prop: 'legalRepresentative'
            },
            {
                label: '联系人',
                prop: 'contact'
            },
            {
                label: '联系电话',
                prop: 'contactNumber'
            },
            {
                label: '电子邮箱',
                prop: 'email'
            }
        ],
        actions: {
            fixedWidth: 0,
            list: []
        }
    };
};

/**
 * @description: 生成抵押物信息表格配置
 * @return {object} 返回策略配置对象
 */
const pawnDetailConfigStrategy = () => {
    return {
        selection: false,
        height: 325,
        columns: [
            {
                label: '名牌',
                prop: 'brand'
            },
            {
                label: '品类',
                prop: 'kind'
            }
        ],
        actions: {
            fixedWidth: 0,
            list: []
        }
    };
};

/**
 * @description: 生成流程信息信息表格配置
 * @return {object} 返回策略配置对象
 */
const iflowDetailConfigStrategy = () => {
    return {
        selection: false,
        height: 325,
        columns: [
            {
                label: '流程类型',
                prop: 'industryTypeCode',
                type: 'select',
                optionsKey: 'MIP_TYPE'
            },
            {
                label: '审批状态',
                prop: 'industryTypeCode',
                type: 'select',
                optionsKey: 'ACCOUNTER_TYPE'
            },
            {
                label: '签约主体',
                prop: 'mip审批流编码'
            },
            {
                label: '创建人',
                prop: 'personName'
            },
            {
                label: '创建时间',
                prop: 'personName'
            },
            {
                label: '审批流',
                prop: 'personName'
            },
            {
                label: '法务审批流',
                prop: 'personName'
            }
        ],
        actions: {
            fixedWidth: 0,
            list: []
        }
    };
};

export default {
    setTableConfig
};
