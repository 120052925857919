<template>
    <div class="flex-layout tianfu-bank-contract">
        <list-layout-paper v-if="!detailFlag">
            <div slot="header-search" class="searchBox">
                <search-box ref="searchBox"
                    :search-key="config.name"
                    :fields="config.searchFields"
                    @search-change="contractList.searchList"/>
            </div>
            <div slot="header-button">
                <el-button size="mini" icon="iconfont icon-refresh" @click='refresh' type="primary" title="刷新" />
            </div>
            <!-- 表格 -->
            <div slot="list" slot-scope="props">
                <table-box
                    ref="clientOrder"
                    :height="props.tableHeight"
                    v-loading="contractList.tableLoading"
                    :selection="config.selection"
                    :index="config.index"
                    :columns="config.tableData.columns"
                    :rows="contractList.totalData"
                    @row-dblclick="viewDetail"
                    @selection-change="handleSelectionChange"
                />
            </div>
            <div slot="footer">
                <lots-pagination
                    @size-change="contractList.sizeChange"
                    :current-page.sync="contractList.pageNo"
                    @current-change="contractList.pageChange"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="contractList.pageTotal"
                ></lots-pagination>
            </div>
        </list-layout-paper>
        <!-- 新增或修改页面 -->
        <section v-if="detailFlag" class='detail'>
            <detail @goBack='goBack' :dataSource="detailData" ref="detail"></detail>
        </section>
    </div>
</template>
<script>
import lotsPagination from '@/components/lots/lotsPagination/Index';
import listLayoutPaper from '@/components/lots/listLayout/Paper';
import searchBox from '@/components/lots/searchBox';
import tableBox from '@/components/lots/tableBox/Index';
import detail from './components/detail';
import { contractListGet } from '@mdm/api/tianfuBankContract.js';
import { AdvanceListClass } from '@/modules/mdm/composition/dc/common/list/AdvanceListClass';
import { config as configStatic } from './config';
import { ref, reactive, onMounted } from '@vue/composition-api';
import { Message } from 'element-ui';
import utils from '@/components/utils/common.js';
export default {
    name: 'tianfuBankContract',
    components: { listLayoutPaper, searchBox, tableBox, detail, lotsPagination },
    /* eslint-disable max-lines-per-function */
    setup(props, setupContext) {
        class ContractListClass extends AdvanceListClass {
            beforeGetList(condition) {
                if (condition.createTime && condition.createTime.length) {
                    condition.startTime = utils.formatDateTime(condition.createTime[0]);
                    condition.endTime = utils.formatDateTime(condition.createTime[1]);
                    Reflect.deleteProperty(condition, 'createTime');
                }
                return condition;
            }
        }
        const contractList = new ContractListClass({
            Api: {
                listApi: contractListGet
            }
        });
        const detailData = reactive({ id: '' });
        const config = reactive(configStatic);
        const detailFlag = ref(false);
        const selectedList = ref([]);
        const refresh = () => {
            contractList.getList();
        };
        const goBack = () => {
            detailFlag.value = false;
            contractList.getList();
        };
        const mutilDelete = () => {
            if (!selectedList.value.length) {
                Message.warning('请先勾选1条数据操作');
                return;
            }
            const ids = selectedList.value.map(item => item.id);
            contractList.deleteRow({ ids }, false);
        };
        const handleSelectionChange = (data) => {
            selectedList.value = data;
        };
        const viewDetail = (row, column, event) => {
            Object.assign(detailData, row);
            detailFlag.value = true;
        };
        onMounted(() => {
            contractList.getList();
        });
        return {
            contractList,
            detailData,
            config,
            detailFlag,
            selectedList,
            refresh,
            goBack,
            mutilDelete,
            handleSelectionChange,
            viewDetail
        };
    }
};
</script>
<style lang="less">
.tianfu-bank-contract {
    .detail {
        width:100%;
    }
    .flex-layout .list-main {
        overflow: auto;
    }
    .flex-layout .table {
        overflow-y: auto;
    }
}
</style>
