<template>
    <div class="tianfu-files-table">
        <table-box
            v-loading="filesDetailList.tableLoading"
            :selection="config.selection"
            :height="config.height"
            :index="config.index"
            :columns="config.columns"
            :rows="filesDetailList.totalData"
            :actions="config.actions"
            @action-click="actionClick"
        />
        <div class="footer">
            <span>
                <lots-button icon="iconfont icon-home_btn_new" v-if="activeName === 'filesDetail' && conctractStatusAddOrDraft"
                    v-has="authorityCode.detailFilesTableAdd" size="mini" type="primary" @click='add'>新增</lots-button>
            </span>
            <lots-pagination
                @size-change='filesDetailList.sizeChange'
                :current-page.sync='filesDetailList.pageNo'
                @current-change='filesDetailList.pageChange'
                size="small" layout='total, sizes, prev, pager, next, jumper'
                :total='filesDetailList.pageTotal'/>
        </div>
        <!-- 新增弹窗 -->
        <el-dialog @close="closeLog" title="附件新增" :visible.sync="addDialog" :close-on-click-modal='false' :close-on-press-escape='false' width="470px">
            <el-form :model="filesForm" label-position="right" class="form-dialog" label-width="80px" :rules="staticConfig.formConfig.rules" ref="filesFormDialog">
                <el-form-item label="附件名称" prop='accessoryName'>
                    <el-input v-model="filesForm.accessoryName" size="mini"></el-input>
                </el-form-item>
                <el-form-item label="文件类型" prop='fileType'>
                    <lots-select optionsKey="SYS_CR_CONTRACT_FILE_TYPE" v-model="filesForm.fileType" @change="fileTypeChange" @clear="filesTypeClear" clearable style="width: 100%" />
                </el-form-item>
                <el-form-item v-if="hasFilesType && filesForm.fileType === 'ContractNeedStamp'" label="选择模板" prop='templateName'>
                    <otpAdvance
                        @getAdvanceValue="getAdvanceValue"
                        :mValue="filesForm.templateName"
                        :params="staticConfig.formConfig.templateAdvance"
                        clearable
                        :disabled="false">
                    </otpAdvance>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <lots-button size="mini" @click="closeLog">取 消</lots-button>
                <lots-button size="mini" type="primary" @click="upload('filesFormDialog')" :disabled="uploadOrHangdleForm">上传附件</lots-button>
                <lots-button size="mini" type="primary" v-loading="btnLoading" :disabled="!uploadOrHangdleForm" @click="handleSave('filesFormDialog')">生成合同</lots-button>
            </span>
        </el-dialog>
        <dialog-import-file
            ref="Import"
            :ImportConfig="staticConfig.dialogImportConfig.ImportConfig"
            :showDownLoad="false"
            @uploadClose="uploadClose"
        ></dialog-import-file>
    </div>
</template>
<script>
import tableBox from '@/components/lots/tableBox/Index';
import lotsPagination from '@/components/lots/lotsPagination/Index';
import lotsSelect from '@/components/lots/lotsSelect/Index';
import otpAdvance from '@/components/lots/otpAdvance/Index.vue';
import DialogImportFile from '@/components/lots/DialogImportFile/Index';
import lotsButton from '@/components/lots/lotsButton';
import btnAuthority from '@/modules/mdm/btnAuthority.js';
import { contractFilesList, contractFilesAdd, contractFilesDownload, contractFilesView, contractFilesDelete, contractCollateralList, contractCustomerList } from '@mdm/api/tianfuBankContract.js';
import { AdvanceListClass } from '@/modules/mdm/composition/dc/common/list/AdvanceListClass';
import { ref, reactive, computed, watch } from '@vue/composition-api';
import { Message } from 'element-ui';
import initConfig from './tableConfig.js';
import commonConfig from './commonConfig.js';

const TABLE_API = {
    filesDetail: {
        listApi: contractFilesList,
        deleteApi: contractFilesDelete
    },
    customerDetail: {
        listApi: contractCustomerList
    },
    pawnDetail: {
        listApi: contractCollateralList
    }
};
export default {
    components: {
        lotsPagination, tableBox, lotsSelect, otpAdvance, DialogImportFile, lotsButton
    },
    props: {
        activeName: {
            type: String,
            default: () => 'filesDetail'
        },
        contractCode: {
            type: String,
            default: () => ''
        },
        conctractStatusAddOrDraft: {
            type: Boolean,
            default: () => false
        }
    },
    /* eslint-disable max-lines-per-function */
    setup(props, setupContext) {
        const { user } = setupContext.root.$store.getters;
        class FilesDetailClass extends AdvanceListClass {
            beforeGetList(condition) {
                condition.contractCode = props.contractCode;
                return condition;
            }
        }
        const filesDetailList = new FilesDetailClass({
            Api: {
                listApi: TABLE_API[props.activeName].listApi,
                deleteApi: TABLE_API[props.activeName].deleteApi && contractFilesDelete
            }
        });
        const refs = setupContext.refs;
        const authorityCode = reactive(btnAuthority.tianfuBankContract);
        const hasFilesType = computed(() => !!filesForm.value.fileType);
        const uploadOrHangdleForm = computed(() => !!(hasFilesType && filesForm.value.templateName));
        const filesForm = ref({
            accessoryName: '',
            fileType: '',
            templateName: '',
            draftModel: ''
        });
        const btnLoading = ref(false);
        const filesBasicParams = reactive({
            contractCode: '',
            userCode: ''
        });
        const staticConfig = reactive(commonConfig);
        const config = reactive(initConfig.setTableConfig(props.activeName));
        const addDialog = ref(false);
        const add = () => {
            addDialog.value = true;
        };
        const actionClick = (event, data) => {
            const { fileId, id, attachmentId, contractName } = data;
            const params = { fileId, id, attachmentId, userCode: user.userCode, contractName };
            switch (event) {
            case 'del':
                filesDetailList.deleteRow(params, false);
                break;
            case 'download':
                download(params);
                break;
            case 'viewFile':
                viewDoc(params);
                break;
            default:
                break;
            }
        };
        const download = (row) => {
            const params = {
                userCode: row.userCode,
                attachmentId: row.attachmentId,
                fileId: row.fileId,
                contractName: row.contractName
            };
            contractFilesDownload(params);
        };
        const viewDoc = (params) => {
            contractFilesView(params).then(res => {
                if (res && +res.code === 0) {
                    window.open(res.data, '附件预览');
                }
            });
        };
        /**
         * @description: 清空文件类型时初始化联动表单模板数据
         */
        const filesTypeClear = (data) => {
            filesForm.value.templateId = '';
            filesForm.value.templateName = '';
            filesForm.value.attachmentId = '';
            filesForm.value.draftModel = '';
        };
        const fileTypeChange = (data) => {
            if (data !== 'ContractNeedStamp') {
                filesTypeClear();
            }
        };
        const handleSave = (formName) => {
            refs[formName].validate((valid) => {
                if (valid) {
                    btnLoading.value = true;
                    contractFilesAdd({ ...filesForm.value, ...filesBasicParams }).then(res => {
                        if (res.code === '0') {
                            Message({
                                message: '生成附件成功',
                                type: 'success'
                            });
                            closeLog();
                        }
                    }).finally(() => {
                        btnLoading.value = false;
                    });
                };
            });
        };
        const upload = (formName) => {
            refs[formName].validate((valid) => {
                if (valid) {
                    const { contractCode } = filesBasicParams;
                    const { userName } = user;
                    const { fileType, accessoryName, tenantCode } = filesForm.value;
                    const urlObject = { contractCode, fileType, userName, accessoryName, tenantCode };
                    const urlParams = Object.keys(urlObject).reduce((pre, cur) => {
                        if (urlObject[cur]) {
                            if (pre.length !== 1) {
                                pre = `${pre}&${cur}=${urlObject[cur]}`;
                            } else {
                                pre = `${pre}${cur}=${urlObject[cur]}`;
                            }
                        }
                        return pre;
                    }, '?');
                    staticConfig.dialogImportConfig.ImportConfig.action = staticConfig.dialogImportConfig.ImportConfig.baseUrl.concat(urlParams);
                    refs.Import.orderImportC();
                };
            });
        };
        // 导入关闭弹窗
        const uploadClose = () => {
            closeLog();
        };
        const closeLog = () => {
            filesForm.value = {
                accessoryName: '',
                fileType: '',
                templateName: '',
                draftModel: ''
            };
            addDialog.value = false;
            filesDetailList.getList();
        };
        // 待办模板组件名称回调
        const getAdvanceValue = (val) => {
            filesForm.value.templateId = val.templateId;
            filesForm.value.templateName = val.templateName;
            filesForm.value.attachmentId = val.attachmentId;
            filesForm.value.draftModel = val.draftModel;
        };
        watch(props, (newVal, oldVal) => {
            if (newVal.contractCode) {
                const { userCode } = user;
                filesBasicParams.contractCode = props.contractCode;
                filesBasicParams.userCode = userCode;
                filesDetailList.getList();
            }
            if (props.conctractStatusAddOrDraft) {
                config.actions.list.forEach(element => {
                    if (element.event === 'del') {
                        element.displayRule = () => true;
                    }
                });
            }
        }, {
            immediate: true
        });
        return {
            filesDetailList,
            config,
            addDialog,
            filesForm,
            filesBasicParams,
            hasFilesType,
            uploadOrHangdleForm,
            staticConfig,
            authorityCode,
            btnLoading,
            add,
            download,
            viewDoc,
            getAdvanceValue,
            actionClick,
            closeLog,
            filesTypeClear,
            fileTypeChange,
            handleSave,
            upload,
            uploadClose
        };
    }
};
</script>
<style lang='less'>
.tianfu-files-table {
    .footer {
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
</style>
