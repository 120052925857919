import configurl from '@/config/user.env';
export default {
    dialogImportConfig: {
        ImportConfig: {
            baseUrl: configurl.apiUrl + 'api-lcrm/contractEnclosureInfo/upload',
            action: configurl.apiUrl + 'api-lcrm/contractEnclosureInfo/upload',
            multiple: false,
            accept: 'pdf/DOC/DOCX',
            showFileList: true,
            fileType: '只能上传pdf、doc、docx文件。',
            manualUpload: false
        }
    },
    formConfig: {
        rules: {
            accessoryName: [
                { required: true, message: '请输入附件名称', trigger: 'change' }
            ],
            fileType: [
                { required: true, message: '请选择文件类型', trigger: 'change' }
            ]
        },
        templateAdvance: {
            name: '模板名称',
            value: 'templateName',
            advanceLabel: '模板弹出框',
            advanceConfig: [
                { prop: 'templateCode', value: '模板编码' },
                { prop: 'templateName', value: '模板名称' }
            ],
            tableConfig: [
                { prop: 'templateCode', value: '模板编码' },
                { prop: 'templateName', value: '模板名称' },
                { prop: 'templateType', value: '模板类型' },
                { prop: 'templateUrl', value: '预览url' }
            ],
            prefix: 'api-lcrm/',
            advanceUrl: 'contractEnclosureInfo/getTemplate',
            cbParams: ['templateCode#templateCode', 'templateName#templateName', 'templateId#templateId', 'attachmentId#attachmentId', 'draftModel#draftModel']
        }
    }
};
