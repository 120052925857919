export default {
    // 组织信息表单配置
    organizationForm: [
        {
            label: '合同编号',
            prop: 'contractCode',
            type: 'input',
            rule: [
                { required: true, message: '合同编号不能为空', trigger: 'blur' }
            ],
            placeholder: '',
            disabled: true
        },
        {
            label: '法务合同编号',
            prop: 'mipContractCode',
            type: 'input',
            placeholder: '',
            disabled: true
        },
        {
            label: '合同名称',
            prop: 'contractName',
            type: 'input',
            rule: [
                { required: true, message: '合同名称不能为空', trigger: 'blur' }
            ],
            placeholder: '',
            disabled: true
        },
        {
            label: '合同性质',
            prop: 'contractCharacter',
            type: 'select',
            rule: [
                { required: true, message: '合同性质不能为空', trigger: 'blur' }
            ],
            placeholder: '',
            disabled: true,
            optionsKey: 'SYS_WM_CONTRACT_CHARACTER'
        },
        {
            label: '主单据编号',
            prop: 'mainContract',
            type: 'custom'
        },
        {
            label: '原单据编号',
            prop: 'origContract',
            type: 'custom'
        },
        {
            label: '会计主体',
            prop: 'accountEntityName',
            type: 'advance',
            rule: [
                { required: true, message: '会计主体不能为空', trigger: 'blur' }
            ],
            placeholder: '',
            value: 'accountEntityName',
            advanceConfig: [
                { 'prop': 'operatingUnitId', 'value': '会计主体编码' },
                { 'prop': 'organizationUnit', 'value': '会计主体名称' }
            ],
            tableConfig: [
                { 'prop': 'operatingUnitId', 'value': '会计主体编码' },
                { 'prop': 'organizationUnit', 'value': '会计主体名称' }
            ],
            prefix: '/api-lcrm',
            advanceUrl: `/contractOuRelatedFinanceInfo`,
            cbParams: ['operatingUnitId#accountEntityId', 'organizationUnit#accountEntityName']
        },
        { // 单选配置
            label: '分公司',
            prop: 'companyName',
            type: 'advance',
            rule: [
                { required: true, message: '分公司不能为空', trigger: 'blur' }
            ],
            value: 'companyName',
            advanceConfig: [
                { 'prop': 'companyCode', 'value': '分公司编码' },
                { 'prop': 'escoCompanyNameCn', 'value': '分公司名称' }
            ],
            tableConfig: [
                { 'prop': 'companyCode', 'value': '分公司编码' },
                { 'prop': 'escoCompanyNameCn', 'value': '分公司名称' }
            ],
            prefix: '/api-mdm',
            advanceUrl: `/esCompany/searchSite?escoCompanyType=C`,
            cbParams: ['companyCode#companyCode', 'escoCompanyNameCn#companyName']
        },
        // { // 多选配置
        //     label: '分公司',
        //     prop: 'companyName',
        //     type: 'advanceMulti',
        //     rule: [
        //         { required: true, message: '分公司不能为空', trigger: 'blur' }
        //     ],
        //     value: 'companyName',
        //     advanceConfig: [
        //         { 'prop': 'companyCode', 'value': '分公司编码' },
        //         { 'prop': 'escoCompanyNameCn', 'value': '分公司名称' }
        //     ],
        //     tableConfig: [
        //         { 'prop': 'companyCode', 'value': '分公司编码' },
        //         { 'prop': 'escoCompanyNameCn', 'value': '分公司名称', 'displayName': true }
        //     ],
        //     prefix: '/api-mdm',
        //     advanceUrl: `/esCompany/searchSite?escoCompanyType=C`,
        //     cbParams: [ 'companyCode#companyCode', 'escoCompanyNameCn#companyName' ],
        //     readonly: true,
        //     afterResponse: function(res) {
        //         res.data.list = res.data.list.map(item => {
        //             const { companyCode, escoCompanyNameCn } = item;
        //             return { companyCode, escoCompanyNameCn };
        //         });
        //         return res;
        //     }
        // },
        // { // 单选配置
        //     label: '服务平台',
        //     prop: 'siteName',
        //     type: 'advance',
        //     rule: [
        //         { required: true, message: '服务平台不能为空', trigger: 'blur' }
        //     ],
        //     value: 'siteName',
        //     advanceConfig: [
        //         { 'prop': 'siteCode', 'value': '服务平台编码' },
        //         { 'prop': 'escoCompanyNameCn', 'value': '服务平台名称' }
        //     ],
        //     tableConfig: [
        //         { 'prop': 'siteCode', 'value': '服务平台编码' },
        //         { 'prop': 'escoCompanyNameCn', 'value': '服务平台名称' },
        //         { 'prop': 'companyCode', 'value': '分公司编码' },
        //         { 'prop': 'escoCompanyNameCn', 'value': '分公司名称' }
        //     ],
        //     prefix: '/api-mdm',
        //     advanceUrl: `/esCompany/searchSite?escoCompanyType=P`,
        //     cbParams: [ 'siteCode#siteCode', 'escoCompanyNameCn#siteName' ],
        //     advanceCascade: 'companyCode#companyCode'
        // },
        { // 多选配置
            label: '服务平台',
            prop: 'siteName',
            type: 'advanceMulti',
            rule: [
                { required: true, message: '服务平台不能为空', trigger: 'blur' }
            ],
            value: 'siteName',
            advanceConfig: [
                { 'prop': 'siteCode', 'value': '服务平台编码' },
                { 'prop': 'escoCompanyNameCn', 'value': '服务平台名称' }
            ],
            tableConfig: [
                { 'prop': 'siteCode', 'value': '服务平台编码' },
                { 'prop': 'escoCompanyNameCn', 'value': '服务平台名称', 'displayName': true },
                { 'prop': 'companyCode', 'value': '分公司编码' },
                { 'prop': 'escoCompanyNameCn', 'value': '分公司名称' }
            ],
            prefix: '/api-mdm',
            advanceUrl: `/esCompany/searchSite?escoCompanyType=P`,
            cbParams: ['siteCode#siteCode', 'escoCompanyNameCn#siteName'],
            advanceCascade: 'companyCode#companyCode',
            readonly: true,
            afterResponse: function (res) {
                res.data.list = res.data.list.map(item => {
                    const { siteCode, companyCode, escoCompanyNameCn } = item;
                    return { siteCode, companyCode, escoCompanyNameCn };
                });
                return res;
            }
        },
        {
            label: '开发单位',
            prop: 'developmentUnit',
            type: 'advance',
            rule: [
                { required: true, message: '开发单位不能为空', trigger: 'blur' }
            ],
            value: 'developmentUnit',
            advanceConfig: [
                { 'prop': 'companyCode', 'value': '开发单位编码' },
                { 'prop': 'escoCompanyNameCn', 'value': '开发单位名称' }
            ],
            tableConfig: [
                { 'prop': 'companyCode', 'value': '开发单位编码' },
                { 'prop': 'escoCompanyNameCn', 'value': '开发单位名称' }
            ],
            prefix: '/api-mdm',
            advanceUrl: `/esCompany/searchSite?escoCompanyType=C`,
            cbParams: ['companyCode#developmentUnit', 'escoCompanyNameCn#developmentUnit']
        },
        {
            label: '主要开发人',
            prop: 'salesStaffName',
            type: 'advance',
            rule: [
                { required: true, message: '主要开发人不能为空', trigger: 'blur' }
            ],
            value: 'salesStaffName',
            advanceConfig: [
                { 'prop': 'esusId', 'value': 'ID' },
                { 'prop': 'esusLoginName', 'value': '美信号' }
            ],
            tableConfig: [
                { 'prop': 'esusId', 'value': 'ID' },
                { 'prop': 'esusLoginName', 'value': '美信号' },
                { 'prop': 'esusUserNameCn', 'value': '姓名' },
                { 'prop': 'esusPositionName', 'value': '职位' },
                { 'prop': 'escoCompanyNameCn', 'value': '部门' }
            ],
            prefix: '/api-mdm',
            advanceUrl: `/selectUserCompany`,
            cbParams: ['esusUserNameCn#salesStaffName', 'esusId#salesStaffCode']
        },
        {
            label: '我方合同联系人',
            prop: 'supplierContactPersonName',
            type: 'advance',
            rule: [
                { required: true, message: '我方合同联系人不能为空', trigger: 'blur' }
            ],
            value: 'supplierContactPersonName',
            advanceConfig: [
                { 'prop': 'esusId', 'value': 'ID' },
                { 'prop': 'esusLoginName', 'value': '美信号' }
            ],
            tableConfig: [
                { 'prop': 'esusId', 'value': 'ID' },
                { 'prop': 'esusLoginName', 'value': '美信号' },
                { 'prop': 'esusUserNameCn', 'value': '姓名' },
                { 'prop': 'esusPositionName', 'value': '职位' },
                { 'prop': 'escoCompanyNameCn', 'value': '部门' }
            ],
            prefix: '/api-mdm',
            advanceUrl: `/selectUserCompany`,
            cbParams: ['esusUserNameCn#supplierContactPersonName', 'esusId#supplierContactPersonCode']
        },
        {
            label: '合同份数',
            prop: 'contractCount',
            type: 'input',
            rule: [
                { required: true, message: '合同份数不能为空', trigger: 'blur' }
            ]
        },
        {
            label: '生效日期',
            prop: 'validTime',
            type: 'time',
            rule: [
                { required: true, message: '生效日期不能为空', trigger: 'blur' }
            ],
            disabled: true,
            format: 'yyyy-MM-dd HH:mm:ss'
        },
        {
            label: '失效日期',
            prop: 'failTime',
            type: 'time',
            rule: [
                { required: true, message: '失效日期不能为空', trigger: 'blur' }
            ],
            placeholder: '',
            disabled: true,
            format: 'yyyy-MM-dd HH:mm:ss'
        },
        {
            label: '业务大类',
            prop: 'bigBusinessType',
            type: 'select',
            rule: [
                { required: true, message: '业务大类不能为空', trigger: 'blur' }
            ],
            placeholder: '',
            disabled: true,
            optionsKey: 'SYS_LMDM_BUS_TYPE'
        },
        {
            label: '合同状态',
            prop: 'contractStatus',
            type: 'select',
            placeholder: '',
            disabled: true,
            optionsKey: 'SYS_CR_CONTRACT_STATUS'
        },
        {
            label: '内外部业务',
            prop: 'oilIsGroupCustomer',
            type: 'select',
            rule: [
                { required: true, message: '内外部业务不能为空', trigger: 'blur' }
            ],
            optionsKey: 'SYS_WM_IN_OR_OUT_CUSTOMER'
        },
        {
            label: '项目编码',
            prop: 'projectCode',
            type: 'input'
        },
        {
            label: '业务类别',
            prop: 'businessType',
            type: 'select',
            rule: [
                { required: true, message: '业务类别不能为空', trigger: 'blur' }
            ],
            placeholder: '',
            disabled: true,
            optionsKey: 'SYS_BMS_BUSI_TYPE'
        },
        // {
        //     label: '客户类型',
        //     prop: 'customerType',
        //     type: 'select',
        //     optionsKey: 'CONTRACT_CUSTOMER_TYPE'
        // },
        {
            label: '价格情况',
            prop: 'priceType',
            type: 'select',
            rule: [
                { required: true, message: '价格情况不能为空', trigger: 'blur' }
            ],
            placeholder: '',
            disabled: true,
            optionsKey: 'SYS_LMDM_PRICE_LEVEL'
        },
        {
            label: '盖章方式',
            prop: 'stampMode',
            type: 'select',
            rule: [
                { required: true, message: '盖章方式不能为空', trigger: 'blur' }
            ],
            placeholder: '',
            disabled: true,
            optionsKey: 'SYS_CONTRACT_STAMP_MODE'
        },
        {
            label: '印章类型',
            prop: 'sealCategory',
            type: 'select',
            optionsKey: 'SYS_CONTRACT_SEAL_CATEGORY',
            rule: [
                { required: true, message: '印章类型不能为空', trigger: 'blur' }
            ]
        },
        // {
        //     label: '印章管理员',
        //     prop: 'sealAdminName',
        //     type: 'input'
        // },
        {
            label: '是否生成水印',
            prop: 'ifWatermarking',
            type: 'select',
            rule: [
                { required: true, message: '是否生成水印不能为空', trigger: 'blur' }
            ],
            placeholder: '',
            disabled: true,
            optionsKey: 'SRM_YES_NO'
        },
        // {
        //     label: '不生成水印原因',
        //     prop: 'noWatermarkingReason',
        //     type: 'input'
        // },
        {
            label: '合同来源',
            prop: 'contractSource',
            type: 'select',
            rule: [
                { required: true, message: '合同来源不能为空', trigger: 'blur' }
            ],
            placeholder: '',
            disabled: true,
            optionsKey: 'CONTRACT_SOURCE'
        },
        {
            label: '产品类型',
            prop: 'productType',
            type: 'select',
            rule: [
                { required: true, message: '产品类型不能为空', trigger: 'blur' }
            ],
            placeholder: '',
            disabled: true,
            optionsKey: 'SYS_CONTRACT_PRODUCT_TYPE'
        }
        // {
        //     label: '最低折扣',
        //     prop: 'lowestDiscount',
        //     type: 'input',
        //     optionsKey: 'SYS_CONTRACT_LOWEST_DISCOUNT'
        // }
    ],
    // 财务信息表单配置
    financeForm: [
        {
            label: '回单收回天数',
            prop: 'receiptRecoveryDays',
            type: 'input',
            rule: [
                { required: true, message: '回单收回天数不能为空', trigger: 'blur' }
            ],
            placeholder: '',
            disabled: true
        },
        {
            label: '对账开票(天)',
            prop: 'reconciliationInvoicingDays',
            type: 'input',
            rule: [
                { required: true, message: '对账开票(天)不能为空', trigger: 'blur' }
            ],
            placeholder: '',
            disabled: true
        },
        {
            label: '回款(天)',
            prop: 'collectionDays',
            type: 'input',
            rule: [
                { required: true, message: '回款(天)不能为空', trigger: 'blur' }
            ],
            placeholder: '',
            disabled: true
        },
        {
            label: '合同账期(天)',
            prop: 'contractAccountOf',
            type: 'input',
            rule: [
                { required: true, message: '合同账期(天)不能为空', trigger: 'blur' }
            ],
            placeholder: '',
            disabled: true
        },
        {
            label: '预计业务量',
            prop: 'expectedTrafficPlan',
            type: 'input',
            rule: [
                { required: true, message: '预计业务量不能为空', trigger: 'blur' }
            ],
            placeholder: '',
            disabled: true
        },
        {
            label: '上年运作规模(万元)',
            prop: 'operationScale',
            class: 'custom-form-label',
            type: 'input'
        },
        {
            label: '采购毛利率(%)',
            prop: 'grossMarginPlan',
            type: 'input',
            rule: [
                { required: true, message: '采购毛利率(%)不能为空', trigger: 'blur' }
            ],
            placeholder: '',
            disabled: true
        },
        {
            label: '押金(万)',
            prop: 'deposit',
            type: 'input',
            rule: [
                { required: true, message: '押金(万)不能为空', trigger: 'blur' }
            ],
            placeholder: '',
            disabled: true
        },
        {
            label: '结算方式',
            prop: 'settlementMode',
            type: 'select',
            rule: [
                { required: true, message: '结算方式不能为空', trigger: 'blur' }
            ],
            optionsKey: 'SYS_SETTLEMENT',
            placeholder: '',
            disabled: true
        },
        {
            label: '承兑比例(%)',
            prop: 'acceptanceRate',
            type: 'input'
        },
        {
            label: '是否油价联动',
            prop: 'oilLinkedProportion',
            type: 'select',
            rule: [
                { required: true, message: '是否油价联动不能为空', trigger: 'blur' }
            ],
            optionsKey: 'SRM_YES_NO',
            placeholder: '',
            disabled: true
        },
        {
            label: '投保方',
            prop: 'insurant',
            type: 'select',
            rule: [
                { required: true, message: '投保方不能为空', trigger: 'blur' }
            ],
            optionsKey: 'SYS_WM_THE_INSURED',
            placeholder: '',
            disabled: true
        },
        {
            label: '税率(%)',
            prop: 'taxRate',
            type: 'input',
            rule: [
                { required: true, message: '税率不能为空', trigger: 'blur' }
            ]
        },
        {
            label: '签单情况',
            prop: 'isNew',
            type: 'select',
            rule: [
                { required: true, message: '签单情况不能为空', trigger: 'blur' }
            ],
            optionsKey: 'SYS_LMDM_SIGNING_SITUATION'
        },
        {
            label: '统计时间',
            prop: 'statisticalTime',
            type: 'time',
            format: 'yyyy-MM-dd HH:mm:ss',
            placeholder: '选择日期时间'
        },
        {
            label: '是否代收货款',
            prop: 'paymentCollection',
            type: 'select',
            rule: [
                { required: true, message: '是否代收货款不能为空', trigger: 'blur' }
            ],
            optionsKey: 'SRM_YES_NO',
            placeholder: '',
            disabled: true
        },
        {
            label: '收款方式',
            prop: 'paymentMethod',
            type: 'select',
            optionsKey: 'SU_PANYMENT_METHOD'
        },
        {
            label: '汇款时间',
            prop: 'remittanceDate',
            type: 'select',
            optionsKey: 'SU_REMITTANCE_DATE'
        },
        {
            label: '收款至',
            prop: 'receiveTo',
            type: 'select',
            optionsKey: 'SU_RECEIVE_TO'
        },
        {
            label: '服务费率(%)',
            prop: 'feeRate',
            type: 'input'
        },
        {
            label: '服务费支付方式',
            prop: 'feePaymentMode',
            type: 'select',
            optionsKey: 'SU_FEE_PAYMENT_MODE'
        }
    ],
    // 天府银行方合同信息表单配置
    forms: [
        {
            label: '动产监管协议合同编号',
            prop: 'superviseCtrNo',
            class: 'custom-form-label',
            type: 'input',
            rule: [
                { required: true, message: '动产监管协议合同编号不能为空', trigger: 'blur' }
            ],
            placeholder: '',
            disabled: true
        },
        {
            label: '额度授信合同编号',
            prop: 'crdtCtrNo',
            type: 'input',
            rule: [
                { required: true, message: '额度授信合同编号不能为空', trigger: 'blur' }
            ],
            placeholder: '',
            disabled: true
        },
        {
            label: '最高额动产浮动抵押合同编号',
            prop: 'guaranteeCtrNo',
            class: 'custom-form-label',
            type: 'input',
            rule: [
                { required: true, message: '最高额动产浮动抵押合同编号不能为空', trigger: 'blur' }
            ],
            placeholder: '',
            disabled: true
        },
        {
            label: '抵押率',
            prop: 'plgRate',
            type: 'input',
            rule: [
                { required: true, message: '抵押率不能为空', trigger: 'blur' }
            ],
            placeholder: '',
            disabled: true
        },
        {
            label: '折扣率',
            prop: 'discountRate',
            type: 'input',
            rule: [
                { required: true, message: '折扣率不能为空', trigger: 'blur' }
            ],
            placeholder: '',
            disabled: true
        },
        {
            label: '签署地点',
            prop: 'signAddr',
            type: 'input',
            rule: [
                { required: true, message: '签署地点不能为空', trigger: 'blur' }
            ],
            span: 12,
            placeholder: '',
            disabled: true
        },
        {
            label: '已盖章签约方',
            prop: 'sealedSignatory',
            type: 'select',
            optionsKey: 'SEALED_SIGNATORY',
            placeholder: '',
            disabled: true
        }
    ]
};
