<template>
    <el-form :model="form" :validate-on-rule-change="false" :rules="rules" ref="form" class="mutil-form">
        <el-row class="form-box">
            <el-col
                v-for="(item, index) in config"
                :key="index"
                :class="['form-item', item.class]"
                :span="_.get(item, 'span', Math.ceil(24 / col))">
                <slot
                    v-if="item.type === 'custom' && _.get(item, 'visible', true)"
                    name="form-append"
                    v-bind="{ form, itemSetting: item, rIndex: index }">
                </slot>
                <el-form-item
                    v-else-if="item.type === 'input' && _.get(item, 'visible', true)"
                    :label="item.label"
                    :prop="item.prop"
                    :label-width="labelWidth">
                    <div class="wrap" :style="{width: _.get(item, 'width', '100%')}">
                        <el-input
                            size="mini"
                            v-model="form[item.prop]"
                            :type="_.get(item, 'inputType', 'text')"
                            :maxlength="_.get(item, 'maxlength', null)"
                            :show-word-limit="_.get(item, 'showWordLimit', false)"
                            :show-password="_.get(item, 'showPassword', false)"
                            :placeholder="_.get(item, 'placeholder', '请输入')"
                            :clearable="_.get(item, 'clearable', true)"
                            :disabled="_.get(item, 'disabled', false)"
                            @change="(data) => { dataChanged(data, form, item); }">
                        </el-input>
                    </div>
                </el-form-item>
                <el-form-item
                    v-else-if="item.type === 'select' && _.get(item, 'visible', true)"
                    :label="item.label"
                    :prop="item.prop"
                    :label-width="labelWidth">
                    <div class="wrap" :style="{width: _.get(item, 'width', '100%')}">
                        <el-select
                            size="mini"
                            v-model="form[item.prop]"
                            :filterable="_.get(item, 'filterable', true)"
                            :multiple="_.get(item, 'multiple', false)"
                            :multiple-limit="_.get(item, 'multipleLimit', 0)"
                            :clearable="_.get(item, 'clearable', true)"
                            :disabled="_.get(item, 'disabled', false)"
                            :placeholder="_.get(item, 'placeholder', '请选择')"
                            @change="(data) => { dataChanged(data, form, item); }">
                            <el-option
                                v-for="(selectItem, selectKey) in item.options"
                                :key="_.isPlainObject(selectItem) ? selectItem.value : selectKey"
                                :label="_.isPlainObject(selectItem) ? selectItem.label : selectItem"
                                :value="_.isPlainObject(selectItem) ? selectItem.value : selectKey"
                                :disabled="_.isPlainObject(selectItem) ? selectItem.disabled : false">
                            </el-option>
                        </el-select>
                    </div>
                </el-form-item>
                <el-form-item
                    v-else-if="item.type === 'advance' && _.get(item, 'visible', true)"
                    :label="item.label"
                    :prop="item.prop"
                    :label-width="labelWidth">
                    <div class="wrap" :style="{width: _.get(item, 'width', '100%')}">
                        <otpAdvance
                            @getAdvanceValue="(data) => { getAdvanceValue(data, form, item); }"
                            :mValue="form[item.value]"
                            :params="item"
                            :disabled="_.get(item, 'disabled', false)"
                            :formData="form">
                        </otpAdvance>
                    </div>
                </el-form-item>
                <el-form-item
                    v-else-if="item.type === 'advanceMulti' && _.get(item, 'visible', true)"
                    :label="item.label"
                    :prop="item.prop"
                    :label-width="labelWidth">
                    <div class="wrap" :style="{width: _.get(item, 'width', '100%')}">
                        <otpAdvanceMulti
                            :ref="item.prop"
                            :params="item"
                            @getAdvanceValue="(data) => { getMultiInfo(data, form, item); }"
                            :disabled="_.get(item, 'disabled', false)"
                            :mValue="form[item.value]"
                            :formData="form">
                        </otpAdvanceMulti>
                    </div>
                </el-form-item>
                <el-form-item
                    v-else-if="item.type === 'time' && _.get(item, 'visible', true)"
                    :label="item.label"
                    :prop="item.prop"
                    :label-width="labelWidth">
                    <div class="wrap" :style="{width: _.get(item, 'width', '100%')}">
                        <el-date-picker
                            v-model="form[item.prop]"
                            :type="_.get(item, 'timeType', 'datetime')"
                            size="mini"
                            :clearable="_.get(item, 'clearable', true)"
                            :disabled="_.get(item, 'disabled', false)"
                            :picker-options="_.get(item, 'pickerOptions', {})"
                            :format="_.get(item, 'format', 'yyyy-MM-dd HH:mm:ss')"
                            :value-format="_.get(item, 'valueFormat', 'yyyy-MM-dd HH:mm:ss')"
                            unlink-panels
                            range-separator="至"
                            :start-placeholder="_.get(item, 'startPlaceholder', '开始日期')"
                            :end-placeholder="_.get(item, 'endPlaceholder', '结束日期')"
                            :placeholder="_.get(item, 'placeholder', '选择日期时间')"
                            @change="(data) => { dataChanged(data, form, item); }">
                        </el-date-picker>
                    </div>
                </el-form-item>
                <el-form-item
                    v-else-if="item.type === 'switch' && _.get(item, 'visible', true)"
                    :label="item.label"
                    :prop="item.prop"
                    :label-width="labelWidth">
                    <div class="wrap" :style="{width: _.get(item, 'width', '100%')}">
                        <el-switch
                            v-model="form[item.prop]"
                            :active-value="_.get(item, 'activeValue', 'Y')"
                            :inactive-value="_.get(item, 'inactiveValue', 'N')"
                            :active-text="item.activeText"
                            :inactive-text="item.inactiveText"
                            @change="(data) => { dataChanged(data, form, item); }">
                        </el-switch>
                    </div>
                </el-form-item>
                <el-form-item
                    v-else-if="item.type === 'radio' && _.get(item, 'visible', true)"
                    :label="item.label"
                    :prop="item.prop"
                    :label-width="labelWidth">
                    <div class="wrap" :style="{width: _.get(item, 'width', '100%')}">
                        <el-radio-group
                            size="mini"
                            v-model="form[item.prop]"
                            :disabled="_.get(item, 'disabled', false)"
                            @change="(data) => { dataChanged(data, form, item); }">
                            <el-radio
                                v-for="(selectItem, selectKey) in item.options"
                                :key="_.isPlainObject(selectItem) ? selectItem.value : selectKey"
                                :label="_.isPlainObject(selectItem) ? selectItem.value : selectKey"
                                :disabled="_.isPlainObject(selectItem) ? selectItem.disabled : false">
                                {{_.isPlainObject(selectItem) ? selectItem.label : selectItem}}
                            </el-radio>
                        </el-radio-group>
                    </div>
                </el-form-item>
                <el-form-item
                    v-else-if="item.type === 'checkbox' && _.get(item, 'visible', true)"
                    :label="item.label"
                    :prop="item.prop"
                    :label-width="labelWidth">
                    <div class="wrap" :style="{width: _.get(item, 'width', '100%')}">
                        <el-checkbox-group
                            size="mini"
                            v-model="form[item.prop]"
                            :disabled="_.get(item, 'disabled', false)"
                            @change="(data) => { dataChanged(data, form, item); }">
                            <el-checkbox
                                v-for="(selectItem, selectKey) in item.options"
                                :key="_.isPlainObject(selectItem) ? selectItem.value : selectKey"
                                :label="_.isPlainObject(selectItem) ? selectItem.value : selectKey"
                                :disabled="_.isPlainObject(selectItem) ? selectItem.disabled : false">
                                {{_.isPlainObject(selectItem) ? selectItem.label : selectItem}}
                            </el-checkbox>
                        </el-checkbox-group>
                    </div>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
</template>

<script>
import _ from 'lodash';
import otpAdvance from '@/components/lots/otpAdvance/Index.vue';
import otpAdvanceMulti from '@/components/lots/otpAdvanceMulti/index.vue';
import { dictWithUrl } from '@/components/lots/api';

export default {
    name: 'mutilForm',
    components: {
        otpAdvance, otpAdvanceMulti
    },
    props: {
        config: { // form表单项的配置
            type: Array,
            required: true,
            default() {
                return [];
            }
        },
        row: { // 数据项对象
            type: Object,
            default() {
                return {};
            }
        },
        loading: { // 表单加载中显示
            type: Boolean,
            default: false
        },
        col: { // 弹窗内列数
            type: Number,
            default: 4
        },
        labelWidth: { // 弹窗label宽度
            type: String,
            default: '140px'
        }
    },
    data() {
        return {
            localLoading: false, // 本地的表单加载中显示
            rules: {}, // 判断表单输入规则集合
            form: {}, // 本地化传入的数据
            keyMap: {}
        };
    },
    computed: {
        _() {
            return _;
        }
    },
    watch: {
        row: { // 监听数据变化，初始化数据
            handler (val) {
                this.form = _.cloneDeep(val);
                this.config.forEach((item) => {
                    // 设置表单的验证规则
                    if (item.rule) {
                        this.$set(this.rules, item.prop, item.rule);
                    }
                    this.setDefaultVal(item);
                });
                // 表格渲染前先判断表格列中使用到的数据字典是否都在浏览器端有备份，没有的进行接口获取，保证表格的字典转义
                const keys = _.chain(this.config).map(obj => obj.optionsKey).filter(str => !_.isNil(str)).value();
                const selectKeyCols = _.chain(this.config).filter(obj => obj.optionsKey).value();
                if (keys.length > 0) {
                    this.localLoading = true;
                    dictWithUrl(keys.join(',')).then(obj => {
                        if (obj.code === '0') {
                            this.localLoading = false;
                            const data = obj.data;
                            // 初始化下拉框的数据字典
                            selectKeyCols.forEach(val => {
                                this.$set(val, 'options', _.get(data, val.optionsKey));
                            });
                        }
                    });
                }
                // const { siteName, companyName } = this.form; // 分公司多选保留
                const { siteName } = this.form;
                if (siteName) {
                    this.branchNameShow(val);
                };
                // if (companyName) { this.cpShow(val); } // 分公司多选保留
            },
            deep: true
        }
    },
    methods: {
        /**
         * @description: 去除空格后，抛出promise
         * @method: 验证表单
         */
        validateForm() {
            const result = new Promise((resolve, reject) => {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        for (const key of Object.keys(this.form)) {
                            const val = this.form[key];
                            if (typeof val === 'string') this.form[key] = val.replace(/(^\s*)|(\s*$)/g, '');
                        }
                        resolve(this.form);
                    } else {
                        reject(this.form);
                        // this.$message.error(`表单中仍有 未填写 或 格式不正确 的项，请检查`);
                    }
                });
            });
            return result;
        },
        /**
         * @description: 获取单选弹窗组件回传数据，并执行通用值改变事件
         * @method: 高级弹窗值改变事件
         * @param {any} data 当前操作项的值
         * @param {object} form form表单数据项对象
         * @param {Object} item 当前操作项的配置项
         */
        getAdvanceValue(data, form, item) {
            for (const key of Object.keys(data)) {
                this.$set(this.form, key, data[key]);
            }
            this.dataChanged(data, form, item);
        },
        /**
         * @description: 监听每个值改变时候，对外暴露方法
         * @method: 值改变事件
         * @param {any} data 当前操作项的值
         * @param {object} form form表单数据项对象
         * @param {Object} item 当前操作项的配置项
         */
        dataChanged(data, form, item) {
            this.$emit('data-changed', { data, form, itemSetting: item });
        },
        /**
         * @description: 为form表单每一项配置数据或默认值
         * @method: 设置默认值
         * @param {Object} item 单个config配置项对象
         */
        setDefaultVal(item) {
            const defaultValue = item.defaultValue === '' || item.defaultValue === undefined || item.defaultValue === null ? '' : item.defaultValue;
            if (this.form[item.prop] === '' || this.form[item.prop] === undefined || this.form[item.prop] === null) {
                this.$set(this.form, item.prop, defaultValue);
            }
            this.setAdaptVal(item);
        },
        /**
         * @description: 对特定类型配置的值进行适配
         * @method: 特殊值适配
         * @param {Object} item 单个config配置项对象
         */
        setAdaptVal(item) {
            // 强转字符串，适配select只认字符串
            if (item.type === 'select' && this.form[item.prop] !== '' && typeof this.form[item.prop] === 'number') this.form[item.prop] = String(this.form[item.prop]);
            // 适配checkbox没有默认值时候，设置为空数组
            if (item.type === 'checkbox' && !(this.form[item.prop] instanceof Array)) this.form[item.prop] = [];
        },
        /**
         * 多选弹窗回调获取信息
         */
        getMultiInfo(data, form, item) {
            const keyArr = Object.keys(data[0]);
            const params = {};
            keyArr.forEach(key => {
                this.keyMap[key] = keyArr;
                if (!params[key]) params[key] = [];
                data.forEach(item => {
                    params[key].push(item[key]);
                });
                params[key] = params[key].join();
            });
            Object.assign(this.form, params);
            this.$emit('data-changed', { params, form, itemSetting: item });
        },
        /**
         * 服务平台-多选弹窗层级回显数据
         */
        branchNameShow(val) {
            // siteCode:'xxx,xss,ddd' siteName:'ss,ddd,xx'
            const { siteCode, siteName } = val;
            const keya = siteCode && siteCode.split(','); // 后端返回缺少字段 siteCode
            const keyb = siteName.split(',');
            const check = [];
            // 'siteCode#siteCode', 'escoCompanyNameCn#siteName'
            if (keya) {
                keya.forEach((itema, index) => {
                    check[index] = { 'siteCode': itema, 'escoCompanyNameCn': keyb[index] };
                });
            } else {
                keyb.forEach((itemB) => {
                    check.push({ 'escoCompanyNameCn': itemB });
                });
            }
            this.$nextTick(() => {
                this.$refs['siteName'][0].detailCheckedList(check || []);
            });
        },
        /**
         * 分公司-多选弹窗层级回显数据
         */
        cpShow(val) {
            // siteCode:'xxx,xss,ddd' siteName:'ss,ddd,xx'
            const { companyCode, companyName } = val;
            const keya = companyCode && companyCode.split(','); // 后端返回缺少字段 companyCode
            const keyb = companyName.split(',');
            const check = [];
            // 'companyCode#companyCode', 'escoCompanyNameCn#companyName'
            if (keya) {
                keya.forEach((itema, index) => {
                    check[index] = { companyCode: itema, escoCompanyNameCn: keyb[index] };
                });
            } else {
                keyb.forEach((itemB) => {
                    check.push({ 'escoCompanyNameCn': itemB });
                });
            }
            this.$nextTick(() => {
                this.$refs['companyName'][0].detailCheckedList(check || []);
            });
        }
    }
};
</script>

<style lang="less">
.mutil-form {
    .form-item {
        & > div { display: inline-block; width: 100%; }
        .el-form-item__label, .el-form-item__content { line-height: 28px; min-height: 29px; }
        .el-date-editor.el-input, .el-select { width: 100%; }
        .el-switch { vertical-align: initial; }
    }
}
</style>
