export const config = {
    name: 'tianfuBankContract',
    searchFields: [
        {
            name: '合同编号',
            value: 'contractCode',
            isFixed: true,
            type: 'input',
            span: 2
        },
        {
            name: '合同名称',
            value: 'contractName',
            isFixed: true,
            type: 'input',
            span: 2
        },
        {
            name: '主要开发人',
            value: 'salesStaffName',
            isFixed: true,
            type: 'advance',
            span: 3,
            advanceConfig: [
                { 'prop': 'esusId', 'value': 'ID' },
                { 'prop': 'esusLoginName', 'value': '美信号' }
            ],
            tableConfig: [
                { 'prop': 'esusId', 'value': 'ID' },
                { 'prop': 'esusLoginName', 'value': '美信号' },
                { 'prop': 'esusUserNameCn', 'value': '姓名' },
                { 'prop': 'esusPositionName', 'value': '职位' },
                { 'prop': 'escoCompanyNameCn', 'value': '部门' }
            ],
            prefix: '/api-mdm',
            advanceUrl: `/selectUserCompany`,
            cbParams: ['esusUserNameCn#salesStaffName', 'esusId#salesStaffCode'],
            advanceCode: 'advanceCode'
        },
        {
            name: '客户编码',
            value: 'customerCode',
            isFixed: true,
            type: 'input',
            span: 2
        },
        {
            name: '客户名称',
            value: 'customerName',
            type: 'advance',
            isFixed: true,
            span: 3,
            advanceConfig: [
                { 'prop': 'customerName', 'value': '客户名称' },
                { 'prop': 'customerCode', 'value': '客户编码' }
            ],
            tableConfig: [
                { 'prop': 'customerCode', 'value': '客户编码' },
                { 'prop': 'customerName', 'value': '客户名称' }
            ],
            prefix: '/api-lcrm',
            advanceUrl: '/contractCustomerInfo/selectCustomerName',
            cbParams: ['customerCode#customerCode', 'customerName#customerName'],
            advanceCode: 'advanceCode'
        },
        {
            name: '创建时间',
            value: 'createTime',
            isFixed: true,
            type: 'time',
            time: 'daterange',
            startPlaceholder: '创建时间从',
            endPlaceholder: '创建时间到',
            span: 5
        }
    ],
    selection: true,
    index: false,
    tableData: {
        columns: [
            {
                label: '合同编号',
                width: 120,
                prop: 'contractCode'
            },
            {
                label: '合同名称',
                width: 120,
                prop: 'contractName'
            },
            {
                label: '合同性质',
                width: 120,
                type: 'select',
                optionsKey: 'SYS_WM_CONTRACT_CHARACTER',
                prop: 'contractCharacter'
            },
            {
                label: '合同状态',
                width: 120,
                type: 'select',
                optionsKey: 'SYS_CR_CONTRACT_STATUS',
                prop: 'contractStatus'
            },
            {
                label: '已盖章签约方',
                width: 120,
                type: 'select',
                optionsKey: 'SEALED_SIGNATORY',
                prop: 'sealedSignatory'
            },
            {
                label: '会计主体',
                width: 120,
                prop: 'accountEntityName'
            },
            {
                label: '分公司',
                width: 120,
                prop: 'companyName'
            },
            {
                label: '服务平台',
                width: 120,
                prop: 'siteName'
            },
            {
                label: '开发单位',
                width: 120,
                prop: 'developmentUnit'
            },
            {
                label: '主要开发人',
                width: 120,
                prop: 'salesStaffName'
            },
            {
                label: '我方合同联系人',
                width: 120,
                prop: 'supplierContactPersonName'
            },
            {
                label: '合同份数',
                prop: 'contractCount'
            }
        ]
    }
};
