<template>
    <div class='container conctract-detail'>
        <list-layout-paper>
            <div slot="header-search" class="detail-header">
                <span>
                    <el-button icon="iconfont icon-home_return" size="mini" @click="goBack">返 回</el-button>
                </span>
                <div class="detail-table-header">
                    <lots-button
                        icon="iconfont
                        icon-upload"
                        size="mini"
                        type="primary"
                        v-has="authorityCode.detailUploadFilesToZHQ"
                        @click="uploadFiles('ToZHQ')">上传智慧签验签</lots-button>
                    <lots-button
                        icon="iconfont
                        icon-preserve"
                        size="mini"
                        type="primary"
                        v-has="authorityCode.detailSave"
                        :disabled="!conctractStatusAddOrDraft"
                        @click='updateDetailData'>保存</lots-button>
                    <lots-button
                        icon="iconfont
                        icon-preserve"
                        size="mini"
                        type="primary"
                        v-loading="applyIflowLoading"
                        v-has="authorityCode.detailApplyIflow"
                        :disabled="!!contractDetailInfo.mipContractCode || !conctractStatusAddOrDraft"
                        @click='applyIflow'>提交审批流程</lots-button>
                    <lots-button icon="iconfont icon-upload" size="mini" type="primary" v-has="authorityCode.detailUploadFilesToTF" @click="uploadFiles('ToTF')">上传天府银行附件</lots-button>
                </div>
            </div>
            <div slot='list'>
                <!-- 上部分表单 -->
                <div class="tabForm">
                    <div class="fold-box" v-for="(item, index) in formData" :key="index">
                        <fold-panel :title="item.formName">
                            <mutil-form :row="item.formModel" :ref="item.formRef" :config="config[item.formRef]" @data-changed="formItemChange">
                                <template v-slot:form-append="{ form, itemSetting }">
                                    <el-form-item
                                        v-if="itemSetting.prop === 'mainContract'"
                                        :label="itemSetting.label"
                                        :prop="itemSetting.prop"
                                        :label-width="'140px'">
                                        <div class="wrap" style="width: '100%'">
                                            <el-input
                                                size="mini"
                                                v-model="form[itemSetting.prop]"
                                                :type="'text'"
                                                placeholder='请输入'
                                                :clearable="true"
                                                :disabled="+form.contractCharacter === 1">
                                            </el-input>
                                        </div>
                                    </el-form-item>
                                    <el-form-item
                                        v-if="itemSetting.prop === 'origContract'"
                                        :label="itemSetting.label"
                                        :prop="itemSetting.prop"
                                        :label-width="'140px'">
                                        <div class="wrap" style="width: '100%'">
                                            <el-input
                                                size="mini"
                                                v-model="form[itemSetting.prop]"
                                                :type="'text'"
                                                placeholder='请输入'
                                                :clearable="true"
                                                :disabled="+form.contractCharacter === 1">
                                            </el-input>
                                        </div>
                                    </el-form-item>
                                </template>
                            </mutil-form>
                        </fold-panel>
                    </div>
                </div>
                <!-- 下部分tab表格 -->
                <div class="tabTable">
                    <div class="fold-box">
                        <fold-panel :title="'更多信息'">
                            <el-tabs v-model="activeName">
                                <el-tab-pane label="附件信息" name="filesDetail">
                                    <detail-footer-table v-if="activeName === 'filesDetail'" :activeName="activeName"
                                     ref='filesDetail'
                                    :conctractStatusAddOrDraft="conctractStatusAddOrDraft"
                                    :contractCode="contractDetailInfo.contractCode" />
                                </el-tab-pane>
                                <el-tab-pane label="客户信息" name="customerDetail">
                                    <detail-footer-table v-if="activeName === 'customerDetail'" :activeName="activeName" :contractCode="contractDetailInfo.contractCode" />
                                </el-tab-pane>
                                <el-tab-pane label="抵押物" name="pawnDetail">
                                    <detail-footer-table v-if="activeName === 'pawnDetail'" :activeName="activeName" :contractCode="contractDetailInfo.contractCode" />
                                </el-tab-pane>
                                <el-tab-pane label="MIP流程" name="iflowDetail">
                                    <iflowTable v-if="activeName === 'iflowDetail'" :currentContract="contractDetailInfo" :contractCode="contractDetailInfo.contractCode"/>
                                </el-tab-pane>
                            </el-tabs>
                        </fold-panel>
                    </div>
                </div>
            </div>
            <span slot="footer">
            </span>
        </list-layout-paper>
    </div>
</template>
<script>
import listLayoutPaper from '@/components/lots/listLayout/Paper';
import FoldPanel from '@/modules/mdm/components/foldPanel/Index.vue';
import mutilForm from './mutilForm.vue';
import lotsButton from '@/components/lots/lotsButton';
import btnAuthority from '@/modules/mdm/btnAuthority.js';
import formConfig from './formConfig';
import { contractDetailGet, contractUpdateByCode, applyIflowApi, pushFileToZHQ, pushFileToTF } from '@mdm/api/tianfuBankContract.js';
import { ref, reactive, onMounted, computed, getCurrentInstance } from '@vue/composition-api';
import detailFooterTable from './detailFooterTable';
import iflowTable from './iflowTable';
import { Message } from 'element-ui';
export default {
    components: {
        listLayoutPaper, FoldPanel, detailFooterTable, iflowTable, mutilForm, lotsButton
    },
    props: {
        dataSource: {
            type: Object,
            default: () => {}
        }
    },
    /* eslint-disable max-lines-per-function */
    setup(props, setupContext) {
        const emit = setupContext.emit;
        const _this = getCurrentInstance();
        const authorityCode = reactive(btnAuthority.tianfuBankContract);
        const formData = ref([
            {
                formRef: 'organizationForm',
                formModel: {},
                formName: '组织信息'
            },
            {
                formRef: 'financeForm',
                formModel: {},
                formName: '财务信息'
            },
            {
                formRef: 'forms',
                formModel: {},
                formName: '天府银行方合同信息'
            }
        ]);
        const refs = setupContext.refs;
        const config = reactive(formConfig);
        const activeName = ref('filesDetail');
        const contractDetailInfo = ref({});
        const conctractStatusAddOrDraft = computed(() => {
            // 当前合同状态为草稿或者新增
            return +contractDetailInfo.value.contractStatus === 0 || +contractDetailInfo.value.contractStatus === 1;
        });
        const initContractDetail = (id) => {
            contractDetailGet({}, id).then(res => {
                if (res && +res.code === 0) {
                    contractDetailInfo.value = res.data;
                    if (+contractDetailInfo.value.contractCharacter === 1) { // 合同性质为“新签”主单据编号和原单据编号只读置空
                        contractDetailInfo.value.mainContract = '';
                        contractDetailInfo.value.origContract = '';
                    }
                    _initForm();
                }
            });
        };
        const updateDetailData = () => {
            const resultArr = formData.value.map(item => refs[item.formRef][0].validateForm());
            Promise.all(resultArr).then(resArr => {
                let params = resArr.reduce((pre, cur) => {
                    return Object.assign(pre, cur);
                }, {});
                params = Object.assign({}, contractDetailInfo.value, params);
                contractUpdateByCode(params, contractDetailInfo.value.contractCode).then((res) => {
                    if (res && +res.code === 0) {
                        Message({
                            message: '保存成功',
                            type: 'success'
                        });
                        initContractDetail(props.dataSource.id);
                    }
                });
            }).catch(() => {
                Message.error(`表单中仍有 未填写 或 格式不正确 的项，请检查~`);
            });
        };
        const applyIflowLoading = ref(false);
        const applyIflow = () => {
            const { user } = setupContext.root.$store.getters;
            const { contractCode: code } = formData.value;
            const { origin, pathname } = window.location;
            const url = `${origin}${pathname}#/mipiframe?contractCode=${code}`;
            const params = {
                contractCode: contractDetailInfo.value.contractCode,
                userCode: user.userCode,
                url
            };
            applyIflowLoading.value = true;
            applyIflowApi(params).then(res => {
                if (res && +res.code === 0) {
                    Message({
                        message: '提交审批流程成功！',
                        type: 'success'
                    });
                    initContractDetail(props.dataSource.id);
                }
            }).finally(() => { applyIflowLoading.value = false; });
        };
        const formItemChange = ({ data, form, itemSetting }) => {
            Object.assign(formData.value, form);
        };
        const _initForm = () => {
            formData.value.forEach(item => {
                if (config[item.formRef]) {
                    const formObj = {};
                    config[item.formRef].forEach(formItem => {
                        const flag = contractDetailInfo.value[formItem.prop] === undefined || contractDetailInfo.value[formItem.prop] === null;
                        if (!flag) {
                            formObj[formItem.prop] = contractDetailInfo.value[formItem.prop];
                        }
                    });
                    if (item.formRef === 'organizationForm') { // 多选回显数据传入
                        formObj['siteCode'] = contractDetailInfo.value['siteCode'];
                        formObj['companyCode'] = contractDetailInfo.value['companyCode'];
                    }
                    item.formModel = Object.assign({}, item.formModel, formObj);
                }
            });
        };
        const filesDetail = ref(null);
        const uploadFiles = (data) => {
            if (data === 'ToZHQ') {
                // 上传智慧签验签
                const params = {
                    // fileId,
                    // businessKey,
                    // externalSealFileUrl,
                    id: props.dataSource.id
                };
                pushFileToZHQ(params).then(res => {
                    if (res && +res.code === 0) {
                        Message({
                            message: '上传智慧签验签成功！',
                            type: 'success'
                        });
                    }
                });
            } else {
                // 上传天府银行附件
                const params = {
                    contractCode: contractDetailInfo.value.contractCode
                    // internalSealFileUrl
                };
                pushFileToTF(params).then(res => {
                    if (res && +res.code === 0) {
                        Message({
                            message: '上传天府银行附件成功！',
                            type: 'success'
                        });
                    }
                });
            }
        };
        const goBack = () => {
            emit('goBack');
        };
        onMounted(() => {
            initContractDetail(props.dataSource.id);
        });
        return {
            _this,
            config,
            activeName,
            formData,
            contractDetailInfo,
            conctractStatusAddOrDraft,
            authorityCode,
            filesDetail,
            applyIflowLoading,
            _initForm,
            updateDetailData,
            applyIflow,
            initContractDetail,
            goBack,
            formItemChange,
            uploadFiles
        };
    }
};
</script>

<style lang='less'>
.conctract-detail {
    .layout-content {
        background: none;
        padding: 0;
        .list-header {
            padding: 20px;
            background-color: #fff;
        }
        .tabForm,.tabTable {
            .fold-box {
                padding: 0 20px;
                background-color: #fff;
                margin-bottom: 10px;
            }
        }
    }
    .detail-header {
        display: flex;
        justify-content: space-between;
    }
    .detail-table-header {
        display: flex;
        justify-content: flex-end;
    }
    .tabForm .custom-form-label .el-form-item--mini .el-form-item__label {
        line-height: 14px;
    }
}
</style>
